<template>
  <div>
    <b-breadcrumb style="margin-bottom:20px">
      <b-breadcrumb-item to="/">
        <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
      </b-breadcrumb-item>
      <b-breadcrumb-item to="/sales" v-if="permission.view"
        >Sales Order List</b-breadcrumb-item
      >
      <b-breadcrumb-item active>Detail Sales</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card no-body>
      <b-card-body title="Detail Sales Order">
        <b-col cols="12">
          <b-row>
            <b-col cols="6">
              <div class="d-flex flex-column">
                <label>Purchase Order</label>
                  <b-link size="md" @click="toPurchase">
                    {{ poNumber[0] }}
                  </b-link>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="d-flex flex-column">
                <div class="d-flex flex-column">
                  <label>Customer PO</label>
                  <p class="font-weight-bold">{{ poNumber[1] }}</p>
                </div>
              </div>

              <div class="d-flex flex-column">
                <label>RSP Order Number</label>
                  {{ sales.PONumber.includes("-")? sales.PONumber.substring(0, 11) : ""}}
              </div>
            </b-col>
            <b-col cols="6">
              <div
                class="d-flex flex-column"
                v-if="sales.deliveryType === 'Internal Warehouse Delivery'"
              >
                <label>Warehouse</label>
                <p class="font-weight-bold">{{ sales.dealer.dealerName }}</p>
              </div>

              <div
                class="d-flex flex-column"
              >
                <label>Industry Type</label>
                <p class="font-weight-bold">{{ sales.distributionChannel }}</p>
              </div>

              <div
                class="d-flex flex-column"
                v-if="sales.deliveryType === 'End Customer'"
              >
                <label>Customer Name</label>
                <p class="font-weight-bold">
                  {{
                    sales.customerDetail.type === "Company"
                      ? sales.customerDetail.companyName
                      : `${sales.customerDetail.firstName} ${sales.customerDetail.lastName}`
                  }}
                </p>
              </div>

              <div
                class="d-flex flex-column"
                v-if="sales.deliveryType === 'Self Collect'"
              >
                <label>Customer Name</label>
                <p class="font-weight-bold">
                  {{
                    sales.customerDetail.firstName === ""
                      ? "Not Set Up"
                      : `${sales.customerDetail.firstName} ${sales.customerDetail.lastName}`
                  }}
                </p>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <div class="d-flex flex-column">
                <label>Fullfillment Type</label>
                <p class="font-weight-bold">
                  {{ sales.orderType }}
                </p>
              </div>
            </b-col>

            <b-col cols="6">
              <div class="d-flex flex-column">
                <label>Delivery Type</label>
                <p class="font-weight-bold">{{ sales.deliveryType }}</p>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <div class="d-flex flex-column">
                <label>Order Status</label>
                <p class="font-weight-bold">{{ sales.orderStatus }}</p>
              </div>
            </b-col>

            <b-col cols="6">
              <div class="d-flex flex-column">
                <label>SAP SO</label>
                <p class="font-weight-bold">{{ sales.sapSo }}</p>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <div class="d-flex flex-column">
                <label>Purchase Date</label>
                <p class="font-weight-bold">{{ dates(sales.createdAt) }}</p>
              </div>
            </b-col>

            <b-col cols="6">
              <div class="d-flex flex-column">
                  <label>Remarks</label>
                  <p class="font-weight-bold">
                    {{ sales.remarks }}
                  </p>
                </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <div class="d-flex flex-column">
                <label>Delivery Date</label>
                <p class="font-weight-bold">{{ dates(sales.deliveryDate) }}</p>
              </div>
            </b-col>
            <b-col cols="6"> </b-col>
          </b-row>

          <b-row style="margin-top:25px;">
            <h5>Sales Order Item List</h5>
            <b-table-simple>
              <b-tr variant="secondary">
                <b-th colspan="4" >Model</b-th>
                <b-th colspan="1" >Qty</b-th>
                <b-th colspan="2" class="text-right">Unit Price</b-th>
                <b-th colspan="2" class="text-right">Amount</b-th>
                <b-th colspan="3" >Reason</b-th>
              </b-tr>
              <b-tr v-for="(item,index) in sales.items" :key="item">
                <b-td colspan="4">{{ item.model }}</b-td>
                <b-td colspan="1">{{ item.qty }}</b-td>
                <b-td colspan="2" class="text-right">{{ formatter(item.price.$numberDecimal) }}</b-td>
                <b-td colspan="2" class="text-right">{{ formatter(parseInt(item.price.$numberDecimal)*item.qty) }}</b-td>
                <b-td colspan="3">{{ item.reasonList }}</b-td>
              </b-tr>
              <b-tr variant="secondary">
                <b-th colspan="7" >Subtotal</b-th>
                <b-td colspan="2" class="text-right">{{formatter(sales.gstCalculation.total)}}</b-td>
                <b-td colspan="3"> - </b-td>
              </b-tr>
              <b-tr>
                <b-th colspan="7">GST {{ sales.gstCalculation.gst }} %</b-th>
                <b-td colspan="2"  class="text-right">{{formatter(sales.gstCalculation.total*sales.gstCalculation.gst/100)}}</b-td>
                <b-td colspan="3"> - </b-td>
              </b-tr>
              <b-tr variant="secondary">
                <b-th colspan="7">Grand Total</b-th>
                <b-td colspan="2" class="text-right">{{formatter(sales.gstCalculation.grandTotal)}}</b-td>
                <b-td colspan="3"> - </b-td>
              </b-tr>
            </b-table-simple>
          </b-row>
          
          <b-row style="margin-top:25px;">
            <h5>Delivery Order List</h5>
            <b-table :items="doList" :fields="doFields">
              <template #cell(sapDo)="row">
                <b-link size="md" @click="toDelivery(row.item)">
                  {{ row.item.sapDo }}
                </b-link>
              </template>
              <template v-slot:cell(deliveryDate)="row">
                {{ dates(row.item.deliveryDate) }}
              </template>
              <template #cell(status)="row">
                <step-progress-daikin :totalStep="(sales.deliveryType=='Self Collect')?3:4" :status="row.item.status"></step-progress-daikin>
              </template>
            </b-table>
          </b-row>
        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import stepProgressDaikin from '@/components/StepProgressDaikin.vue'
import { userAccess, dateFormat } from "@/utils/utils";

export default {
  props: ["idSales"],
  
  components: {
    stepProgressDaikin
  },
  data() {
    return {
      salesOrder: {
        SONumber: "-",
        orderType: "",
        orderStatus: "-",
        sapSo: "-",
        sapDo: "-",
        deliveryType: "-",
        deliveryDetail: {
          customerName: "-",
        },
        itemDetails: [],
      },
      doFields: [
        { key: "sapDo", label: "SAP DO" },
        { key: "sapInvoice", label: "Invoice Number" },
        { key: "status", label: "Status" },
      ],
      itemFields: [
        { key: "model", text: "Item Model" },
        { key: "qty", text: "Qty" },
        { key: "price", text: "Price" },
        // { key: 'rejectedQty', text: 'Rejected Qty' },
        { key: "rejectionReason", text: "Reason" },
      ],
      doList:[]
    };
  },
  methods: {
    dates(date) {
      return dateFormat(date);
    },
    async getDOList(sapSo) {
      //return [{sapDo:1,sapInvoice:1}]
      var filterDO = await this.$store.dispatch("delivery/getDeliveryOrder",{sapSo:'0000'+sapSo})
        if(filterDO.data.length<=0){
          console.info("undefined")
          this.doList=[]
          return
        }

        var jData = filterDO.data.map((value)=>{
          return {
            id:value._id,
            sapDo:value.sapDo,
            sapInvoice:value.sapInvoice,
            status:value.status
          }
        })
        console.info('data ',jData)
        console.info('filterDO ',filterDO.data)
        this.doList=jData
        return
      
      
    },
    toPurchase() {
      var sapSo = this.sales.sapSo;
      var filterSO = this.purchaseOrder.find((x) => {
        return x.sapSo === sapSo;
      });
      this.$router.push({ name: "order-detail", params: { id: filterSO._id } });
    },
    toDelivery(doDetail) {
      //console.info('doDetail ',doDetail)
      this.$router.push({
        name: "warehouse-detail-delivery",
        params: { id: doDetail.id, from: "other" },
      });
    },
    formatter(value) {
      var number = value;
      if (!number) {
        number = 0;
      }
      return (Math.round(number * 100) / 100).toFixed(2);
    },
  },
  computed: {
    sales() {
      return this.$store.getters["sales/salesById"];
    },
    purchaseOrder() {
      return this.$store.getters["purchase/purchase"];
    },
    permission() {
      let result = userAccess("View Sales Order", "sales_order_menu");
      // console.log("View Sales Order",result)
      return result;
    },
    poNumber(){
      if(this.sales==undefined||this.sales.length<=0){
        return ["",""]
      }
      console.info('sales  ',this.sales)
      var split = this.sales.PONumber.split("-");
      return split;
    },
  },
  mounted() {
    //this.$store.dispatch("delivery/getDeliveryOrder");

    this.$store.dispatch("purchase/getPurchase", { orderStatus: "ALL" });

    this.$store
      .dispatch("sales/detailSales", {
        id: this.idSales,
      })
      .then((res)=>{
        this.getDOList(this.sales.sapSo)
      })
      .catch((err) => {
        console.log({ err });
      });
  },
};
</script>
